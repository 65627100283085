import styled from "styled-components"
import { $BgColor } from "typings/modules"

export const Container = styled.div<$BgColor>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;
`

export const ContentContainer = styled.div`
  padding: 32px 0;
  width: 80%;
  margin: auto;
  display: grid;
  align-content: center;
  grid-gap: 24px;
`
