import React, { useEffect } from "react"
import { usdFmt } from "utils/priceUtils"
import { useVariantOptions } from "hooks/useVariantOptions"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import { decodeVariantId } from "utils/decodeId"
import { tracking } from "utils/tracking"
import { Checkbox } from "components/UI/Checkbox"
import {
  ItemContainer,
  ItemSelector,
  ItemDescription,
  ImageContainer,
  ItemLink,
  ItemTitle,
  FBTCheckbox,
} from "./FBTDesktopItem.styles"
import { GatsbyImage } from "gatsby-plugin-image"
import { FBTDesktopItemProps } from "./FBT.types"
import { Link } from "gatsby"
import { VariantOptions } from "components/Products/VariantOptions"

export const FBTDesktopItem = (props: FBTDesktopItemProps) => {
  const { index, item, onClick, onChange } = props
  const {
    selectedVariant,
    image,
    imageAlt,
    selectableOptions,
    updateSelectedVariant,
  } = useVariantOptions(item)
  const variantId = item.variant?.id || ""
  const description = item.item.description || ""
  const productPath = item.item.product?.path?.current
  const productPathPrefix = useProductPathPrefix()
  const price = selectedVariant?.price ?? ""
  const decodedVariantId = decodeVariantId(variantId)
  const usdPrice = price ? usdFmt(price) : ""
  const selected = item.isSelected
  const link = `${productPathPrefix}${productPath}/?variant=${decodedVariantId}`
  useEffect(() => {
    if (selectedVariant?.availableForSale) {
      if (!selected) {
        onClick(index, true)
      }
      onChange(index, selectedVariant.id)
    } else {
      onClick(index, false)
    }
  }, [selectedVariant])
  return (
    <ItemContainer data-test={item.product?.title}>
      {image && (
        <ImageContainer>
          <Link to={link}>
            <GatsbyImage alt={imageAlt} image={image?.gatsbyImage} />
          </Link>
        </ImageContainer>
      )}
      <ItemSelector
        data-test="item-selector"
        onClick={() => {
          if (selectedVariant?.availableForSale) {
            onClick(index, !selected)
          }
          tracking.fbtSelected(item.product, item.variant, !item.isSelected)
        }}
      >
        <FBTCheckbox>
          <Checkbox $selected={selected} />
        </FBTCheckbox>
      </ItemSelector>
      <ItemLink to={link}>
        <ItemTitle>
          {item.product?.title} - {usdPrice}
        </ItemTitle>
      </ItemLink>
      <ItemDescription>{description}</ItemDescription>
      <VariantOptions
        selectedVariant={selectedVariant}
        selectableOptions={selectableOptions}
        updateSelectedVariant={updateSelectedVariant}
        config={{
          dropdown: { maxWidth: "66.6%", center: true },
        }}
      />
    </ItemContainer>
  )
}
