import React, { useState, useEffect, useContext } from "react"
import { ThemeContext, ThemeProvider } from "styled-components"
import { usdFmt } from "utils/priceUtils"
import { StoreContext } from "contexts/StoreContext"
import { FBTDesktop } from "./FBTDesktop"
import { FBTMobile } from "./FBTMobile"
import { useProductList } from "hooks/useProductList"
import { FBTItemType, VariantConn, FBTProps } from "./FBT.types"
import {
  LargeAndBelow,
  LargerAndUp,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"

export const FBT = ({ content, internalName, products, bgColor }: FBTProps) => {
  const themeContext = useContext(ThemeContext)
  const { plItems, loading } = useProductList(products)
  const { addLineItems, setCartOpen } = useContext(StoreContext)
  const [fbtItems, setFbtItems] = useState<FBTItemType[]>([])
  const [price, setPrice] = useState<string>("")
  const toggleSelected = (itemIdx: number, isSelected: boolean) => {
    const newItems = fbtItems.map((item, index) =>
      itemIdx === index ? { ...item, isSelected } : item
    )
    setFbtItems([...newItems])
  }
  const amtSelected = fbtItems.filter(item => item.isSelected).length
  const disabled = amtSelected === 0
  const buttonText =
    amtSelected === 3
      ? "Add 3 to Cart"
      : amtSelected === 2
      ? "Add 2 to Cart"
      : amtSelected < 2
      ? "Add To Cart"
      : ""
  const addItemsToCart = () => {
    const lineItems = fbtItems
      .filter(item => item.isSelected)
      .map(item => ({ variantId: item.activeVariantId, quantity: 1 }))
    addLineItems(lineItems)
    setCartOpen(true)
  }
  const changeActiveVariant = (itemIdx: number, activeVariantId: string) => {
    const newItems = fbtItems.map((item, index) =>
      index === itemIdx ? { ...item, activeVariantId, isSelected: true } : item
    )
    setFbtItems([...newItems])
  }
  useEffect(() => {
    const nextPrice = fbtItems
      .filter(item => item.isSelected)
      .reduce((acc, item) => {
        const activeId = item.activeVariantId
        const variants = (item.product?.variants as unknown) as VariantConn
        const variant = variants?.edges.find(edge => edge.node?.id === activeId)
        return (acc += Number(variant?.node.price))
      }, 0)
    setPrice(usdFmt(nextPrice))
  }, [fbtItems])
  useEffect(() => {
    if (!loading && plItems?.[0]?.variant) {
      const itemsWithAddons = plItems.map(item => {
        const activeVariant = item.item.displayVariant
        const activeVariantId = activeVariant?.variantId ?? ""
        const isAvailable = item?.variant?.availableForSale
        const addons = { isSelected: isAvailable, activeVariantId }
        return { ...item, ...addons }
      })
      setFbtItems(itemsWithAddons)
    }
  }, [plItems, loading])
  const props = {
    internalName,
    content,
    bgColor,
    disabled,
    buttonText,
    totalPrice: price,
    items: fbtItems,
    onClick: toggleSelected,
    onChange: changeActiveVariant,
    onSubmit: addItemsToCart,
  }
  if (!products.length) return null
  return (
    <ThemeProvider
      theme={{
        localTheme: themeContext?.dark,
      }}
    >
      <LargeAndBelow>
        <FBTMobile {...props} />
      </LargeAndBelow>
      <LargerAndUp>
        <FBTDesktop {...props} />
      </LargerAndUp>
    </ThemeProvider>
  )
}
