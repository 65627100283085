import { mq, typography } from "styles"
import styled from "styled-components"
import { Link } from "gatsby"
import { $Selected } from "typings/modules"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

export const Item = styled.div`
  border-top: 1px solid ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
  position: relative;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 72px 1px 1fr;
`

export const ItemContent = styled.div<$Selected>`
  padding: 16px 24px;
  transition: opacity 0.3s ease;
  opacity: ${({ $selected }) => ($selected ? 1 : 0.5)};
  ${mq.minWidth("md")} {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr auto;
    grid-gap: 56px;
    align-items: center;
    justify-content: space-between;
  }
`

export const ItemTitle = styled.h5`
  margin: 0;
  font-size: 16px;
  line-height: 28px;
  ${mq.minWidth("md")} {
    line-height: 32px;
  }
`

export const ItemLink = styled(Link)`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  text-decoration: none;
`

export const CheckboxContainer = styled(NoStyleButton)<$Selected>`
  transition: opacity 0.3s ease;
  opacity: ${({ $selected }) => ($selected ? 1 : 0.5)};
  width: 72px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    position: static;
  }
`

export const Divider = styled.span`
  display: block;
  height: 100%;
  width: 1px;
  background: ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
`

export const ItemDescription = styled.div`
  ${typography.bodySmall};
  margin: 0;
`

export const ItemContentLeftSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`
