import React from "react"
import { SanityFbt } from "typings/graphql"
import { FBT } from "./FBT"

export const SanityFBTBlock = ({ fields }: { fields: SanityFbt }) => (
  <FBT
    internalName={fields.internalName}
    content={fields._rawContent}
    products={fields.products}
    bgColor={fields.bgColor?.opacityHex}
  />
)

export default SanityFBTBlock
