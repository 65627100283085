import styled from "styled-components"
import { Link } from "gatsby"
import { mq, typography } from "styles"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"

export const ItemContainer = styled.div`
  text-align: center;
  position: relative;
  padding: 32px 0;
`

export const ItemTitle = styled.h5`
  ${typography.body};
  font-weight: bold;
  margin: 16px 0 4px;
`

export const ItemDescription = styled.div`
  width: 66.6%;
  margin: auto;
  ${typography.bodySmall};
  margin-bottom: 16px;
`

export const ItemLink = styled(Link)`
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`

export const ImageContainer = styled.div`
  display: block;
  margin: 0 auto;
  width: 50%;
  transition: opacity 0.3s ease;
  &:hover {
    opacity: 0.5;
  }
`

export const ItemSelector = styled(NoStyleButton)`
  width: 100%;
  z-index: 2;
`

export const FBTCheckbox = styled.div`
  position: absolute;
  right: auto;
  top: auto;
  ${mq.minWidth("md")} {
    right: 19px;
    top: 19px;
  }
  ${mq.minWidth("lg")} {
    right: 27px;
    top: 27px;
  }
`
