import React, { useEffect, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { FBTMobileItem } from "./FBTMobileItem"
import { Button } from "components/UI/Button/Button"
import { PortableText } from "components/UI/PortableText/PortableText"
import {
  Container,
  Content,
  Photos,
  Items,
  Box,
  Trigger,
  ButtonContainer,
  ButtonIcon,
  ChevronUp,
  ChevronDown,
} from "./FBTMobile.styles"
import { FBTMobileProps, Photo } from "./FBT.types"
import { useClientRect } from "hooks/useClientRect"

export const FBTMobile = (props: FBTMobileProps) => {
  const {
    internalName,
    bgColor,
    content,
    items,
    buttonText,
    onClick,
    disabled,
    onSubmit,
    totalPrice,
    onChange,
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [photos, setPhotos] = useState<(Photo | undefined)[]>([])
  const setPhoto = (photo: Photo) => {
    setPhotos(prevState => {
      const newState = [...prevState]
      newState[photo.index] = photo
      return newState
    })
  }
  const [addToCartRect, addToCartRef] = useClientRect()

  useEffect(() => {
    if (isOpen && addToCartRect?.bottom) {
      const footerHeight = 87
      const buttonHeight = 55
      const margin = 32
      const fixedFooterOffset =
        window.innerHeight - footerHeight - buttonHeight - margin
      const elementPosition = addToCartRect.bottom
      const offsetPosition = elementPosition - fixedFooterOffset

      window.scrollBy({
        top: offsetPosition,
        behavior: "smooth",
      })
    }
  }, [addToCartRect?.bottom, isOpen])
  return (
    <Container $bgColor={bgColor} data-test={`fbt-${internalName}`}>
      <Content>
        <PortableText blocks={content} />
      </Content>
      <Photos>
        {photos.map(
          (photo, i) =>
            photo?.gatsbyImage && (
              <GatsbyImage
                key={`fbt-mobile-photo-${i}`}
                image={photo?.gatsbyImage}
                alt={photo?.description}
              />
            )
        )}
      </Photos>
      <Items>
        <Box>
          <Trigger
            aria-label={buttonText}
            aria-pressed={isOpen}
            data-test={`fbt-${internalName}-trigger`}
            onClick={() => setIsOpen(!isOpen)}
          >
            {buttonText}
            <ButtonIcon>
              {isOpen ? (
                <ChevronUp role="img" aria-label="Chevron Up Icon" />
              ) : (
                <ChevronDown role="img" aria-label="Chevron Down Icon" />
              )}
            </ButtonIcon>
          </Trigger>
          {items.map((item, i) => (
            <FBTMobileItem
              key={`fbt-mobile-item-${i}`}
              index={i}
              setPhoto={setPhoto}
              onChange={onChange}
              onClick={onClick}
              isOpen={isOpen}
              item={item}
            />
          ))}
        </Box>
      </Items>
      {isOpen && (
        <ButtonContainer ref={addToCartRef}>
          <Button
            data-test={`fbt-${internalName}-button`}
            fullWidth
            variant="primary-dark"
            disabled={disabled}
            onClick={onSubmit}
          >
            {buttonText} - {totalPrice}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  )
}
