import React from "react"
import { FBTDesktopItem } from "./FBTDesktopItem"
import { FBTDesktopProps } from "./FBT.types"
import { Button } from "components/UI/Button/Button"
import { Container, ContentContainer } from "./FBTDesktop.styles"
import { PortableText } from "components/UI/PortableText/PortableText"

export const FBTDesktop = (props: FBTDesktopProps) => {
  const {
    internalName,
    bgColor,
    content,
    items,
    onClick,
    onSubmit,
    disabled,
    buttonText,
    totalPrice,
    onChange,
  } = props
  return (
    <Container $bgColor={bgColor} data-test={`fbt-${internalName}`}>
      <ContentContainer>
        <PortableText blocks={content} />
        <Button
          data-test={`fbt-${internalName}-button`}
          fullWidth
          variant="primary-dark"
          disabled={disabled}
          onClick={onSubmit}
        >
          {buttonText} - {totalPrice}
        </Button>
      </ContentContainer>
      {items?.map((item, i) => (
        <FBTDesktopItem
          key={`fbt-item-${i}`}
          index={i}
          item={item}
          onClick={onClick}
          onChange={onChange}
        />
      ))}
    </Container>
  )
}
