import styled from "styled-components"
import { NoStyleButton } from "components/UI/Button/NoStyleButton"
import { mq, typography } from "styles"
import { $BgColor } from "typings/modules"
import Chevron from "assets/svg/icon-chevron-back.svg"

export const Container = styled.div<$BgColor>`
  background-color: ${({ $bgColor }) => $bgColor};
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
  padding: 64px 0 32px;
  ${mq.minWidth("md")} {
    padding: 64px 0 48px;
  }
`

export const Content = styled.div`
  text-align: center;
  width: 83.33%;
  margin: 0 auto 24px;
`

export const Items = styled.div`
  width: 83.33%;
  margin: 0 auto;
`

export const Box = styled.div`
  border: 1px solid ${({ theme }) => theme.dark.ui?.divider?.opacityHex};
  margin: 30px 0 0;
  ${mq.minWidth("md")} {
    margin: 48px 0 0;
  }
`

export const Trigger = styled(NoStyleButton)`
  height: 56px;
  ${typography.body};
  text-align: center;
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
`

export const ButtonContainer = styled.div`
  width: 83.33%;
  margin: 32px auto 0;
`

export const ButtonIcon = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 21px;
  svg {
    path {
      fill: ${({ theme }) => theme.dark.text?.primary?.opacityHex};
    }
  }
`

export const Photos = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
`

export const ChevronUp = styled(Chevron)`
  transform: rotate(90deg);
`

export const ChevronDown = styled(Chevron)`
  transform: rotate(-90deg);
`
