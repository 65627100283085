import React, { useEffect } from "react"
import { usdFmt } from "utils/priceUtils"
import { decodeVariantId } from "utils/decodeId"
import { tracking } from "utils/tracking"
import { Checkbox } from "components/UI/Checkbox"
import { useVariantOptions } from "hooks/useVariantOptions"
import { useProductPathPrefix } from "hooks/useProductPathPrefix"
import {
  Item,
  CheckboxContainer,
  Divider,
  ItemContent,
  ItemDescription,
  ItemLink,
  ItemTitle,
  ItemContentLeftSide,
} from "./FBTMobileItem.styles"
import { FBTCheckbox } from "./FBTDesktopItem.styles"
import { useContext } from "react"
import { VariantSettingsContext } from "contexts/VariantSettingsContext"
import { FBTMobileItemProps } from "./FBT.types"
import { VariantOptions } from "components/Products/VariantOptions"
import {
  LargeAndUp,
  MediumAndBelow,
} from "components/UI/MediaQueryContainers/MediaQueryContainers"

export const FBTMobileItem = (props: FBTMobileItemProps) => {
  const { index, item, onClick, setPhoto, isOpen, onChange } = props
  const {
    selectedVariant,
    image,
    imageAlt,
    selectableOptions,
    updateSelectedVariant,
  } = useVariantOptions(item)
  const { getFirstSwatchTypeName } = useContext(VariantSettingsContext)
  const swatchTypeName = getFirstSwatchTypeName(selectableOptions)
  const activeVariantId = item?.activeVariantId || ""
  const description = item.item.description || ""
  useEffect(() => {
    if (image?.gatsbyImage) {
      setPhoto({
        index,
        id: activeVariantId,
        gatsbyImage: image?.gatsbyImage,
        description: imageAlt,
      })
    }
  }, [image?.gatsbyImage, item.product?.id])
  const variantId = selectedVariant?.shopifyVariant?.variantId ?? ""
  const price = selectedVariant?.price ?? ""
  const usdPrice = price ? usdFmt(price) : ""
  const selected = item.isSelected
  const decodedVariantId = decodeVariantId(variantId)
  const productPathPrefix = useProductPathPrefix()
  useEffect(() => {
    if (selectedVariant?.availableForSale) {
      if (!selected) {
        onClick(index, true)
      }
      onChange(index, selectedVariant.id)
    } else {
      onClick(index, false)
    }
  }, [selectedVariant])
  const variantOptionsProps = {
    selectedVariant,
    updateSelectedVariant,
    selectableOptions,
  }
  return isOpen ? (
    <>
      <Item data-test={item.product.title}>
        <CheckboxContainer
          data-test="item-selector"
          $selected={item.isSelected}
          onClick={() => {
            if (selectedVariant?.availableForSale) {
              onClick(index, !selected)
            }
            tracking.fbtSelected(item.product, item.variant, !item.isSelected)
          }}
        >
          <FBTCheckbox>
            <Checkbox $selected={item.isSelected} />
          </FBTCheckbox>
        </CheckboxContainer>
        <Divider />
        <ItemContent $selected={item.isSelected}>
          <ItemContentLeftSide>
            <ItemLink
              to={`${productPathPrefix}${item.item?.product?.path?.current}/?variant=${decodedVariantId}`}
            >
              <ItemTitle>
                {item.product.title} - {usdPrice}
              </ItemTitle>
              <ItemDescription>{description}</ItemDescription>
            </ItemLink>
            <LargeAndUp>
              <VariantOptions
                {...variantOptionsProps}
                renderOnlyVariantTypes={variantTypeName =>
                  variantTypeName !== swatchTypeName
                }
                config={{
                  dropdown: {
                    maxWidth: "240px",
                  },
                }}
              />
            </LargeAndUp>
            <MediumAndBelow>
              <VariantOptions
                {...variantOptionsProps}
                config={{
                  dropdown: {
                    maxWidth: "240px",
                  },
                }}
              />
            </MediumAndBelow>
          </ItemContentLeftSide>
          <LargeAndUp>
            <VariantOptions
              {...variantOptionsProps}
              renderOnlyVariantTypes={variantTypeName =>
                variantTypeName === swatchTypeName
              }
            />
          </LargeAndUp>
        </ItemContent>
      </Item>
    </>
  ) : null
}
