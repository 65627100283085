import { useCallback, useState } from "react"

export const useClientRect = (): [DOMRect, (node: HTMLElement) => void] => {
  const [rect, setRect] = useState<DOMRect>(null)
  const ref = useCallback((node: HTMLElement) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect())
    }
  }, [])
  return [rect, ref]
}
