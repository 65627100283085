import React from "react"
import styled from "styled-components"
import { $Selected, $Hover, $Pressed } from "typings/modules"
import Checkmark from "assets/svg/check.svg"

type Props = $Selected & Partial<$Hover & $Pressed>

const Container = styled.span<$Selected & $Hover & $Pressed>`
  background-color: ${({ $selected, theme }) =>
    theme.global.checkbox?.[$selected ? "activeFill" : "fill"]?.opacityHex};
  border: 1px solid
    ${({ $selected, theme }) =>
      theme.global.checkbox?.[$selected ? "activeStroke" : "stroke"]
        ?.opacityHex};
  color: ${({ theme }) => theme.global.checkbox?.checkmark?.opacityHex};
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  ${({ $hover, $selected, theme }) =>
    $hover &&
    `
    background-color: ${
      theme.global.checkbox?.[$selected ? "activeHoverFill" : "hoverFill"]
        ?.opacityHex
    };
    border-color: ${
      theme.global.checkbox?.[$selected ? "activeHoverStroke" : "hoverStroke"]
        ?.opacityHex
    };
  `}
  ${({ $pressed, theme }) =>
    $pressed &&
    `
    background-color: ${theme.global.checkbox?.pressedFill?.opacityHex};
    border-color: ${theme.global.checkbox?.pressedFill?.opacityHex};
  `}
`

export const Checkbox = ({ $selected, $hover, $pressed }: Props) => {
  return (
    <Container
      aria-label="Checkbox"
      aria-pressed={$selected}
      $selected={$selected}
      $hover={$hover}
      $pressed={$pressed}
    >
      {$selected && <Checkmark role="img" aria-label="Checkmark Icon" />}
    </Container>
  )
}
